import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom"
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ProgressBar } from 'react-bootstrap'
import { customCurrencySelect, DashBoardModalBody, Loading, sharedDashboardHeader } from '../components/sharedStyles'
import { breakPoints, currencies, mainColors } from '../constants/constants'
import { openModal } from '../redux/actions/modal-custom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import PageMetaTitle from '../components/PageMetaTitle'
import DashLayout from './partials/DashLayout'
import { createProject, getProjects } from '../redux/actions/projects'
import LtcBadge from '../components/Ltc-badge'
import CustomLinkButton from '../components/CustomLinkButton'
import { paths } from './Project'
import ProjectImage from '../assets/projects/project.png'
import { FormikInput } from '../components/FormikInput'
import FormikSelect from '../components/FormikSelect'

import { ReactComponent as Plus } from '../assets/icons/plus.svg'
import { ReactComponent as MapPin } from '../assets/icons/map_pin.svg'
import { ReactComponent as Bank } from '../assets/icons/bank.svg'
import { ReactComponent as SmallPlus } from '../assets/icons/small_plus.svg'
import { ReactComponent as ArrowUp } from '../assets/icons/arrow_drop_up.svg'
import { convertToInternationalCurrencySystem } from '../components/helpers'

const Header = styled.div`
  ${sharedDashboardHeader}
  display: flex;
  align-items: center;
  justify-content: center;
`

const AddProject = styled.button`
  background: ${mainColors.blue};
  border-radius: 10px;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  outline: none;
  border: none;
`

const Grid = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(534px, 1fr));
  grid-gap: 30px;

  @media (max-width: ${breakPoints.sm}) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`

const ProjectWrapper = styled.div`
  width: 100%;
  background: ${mainColors.white};
  border-radius: 16px;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakPoints.sm}) {
    flex-direction: column;
  }

  .project-details {
    padding: 16px 24px;

    .header-nav-title {
      text-decoration: none;
    }

    h4 {
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;
      color: ${mainColors.dashboardDarkBlue};
      display: block;
      margin: 0;
    }

    .project-address {
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: 24px;
      color: #A3AED0;

      svg {
        margin-right: 10px;
      }
    }

    .project-bank {
      background: #ECECEC;
      border-radius: 4px;
      font-size: 13px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #515058;
      margin-top: 17px;
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 15px;
      max-width: 226px;
      width: 100%;

      &-currency {
        margin-right: 10px;
      }

      &-name {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 226px;

        .icon {
          line-height: 1;
        }

        .name {
          margin-left: 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .project-draw {
      font-size: 13px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      color: ${mainColors.blue};

      svg {
        margin-right: 10px;
      }

      .draw-value {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 8px;

        div {
          font-size: 13px;
          line-height: 24px;
          color: #515058;
        }
      }

      .progress {
        width: 100%;
        border-radius: 4px;
        height: 8px;
      }

      &-start {
        div:first-child {
          font-size: 13px;
          color: #A3AED0;
        }
        div:last-child {
          span {
            color: #515058;
            padding-left: 3px;
          }
        }
      }
    }
  }

  .project-image {
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 15px;
    }
  }
`

const StyledLtcBadge = styled(LtcBadge)`
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 13px;
  max-width: 86px;
  padding-right: 5px;

  .ltc-circle {
    width: 12px;
    height: 12px;
  }
  .ltc-text {
    margin-right: 8px;
    margin-left: 4px;
  }
`

const LoaderWrapperFull = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`

const SubmitWrapper = styled.div`
  width 100%;

  button {
    width: 100%;
    margin-left: 0 !important;
  }
`

const formatTitle = (title) => title.toLowerCase().trim().split(/\s+/).join('-')
const linkPath = (project) => `/project/${formatTitle(project.title) + paths.budget}`

const ModalBody = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const saving = useSelector(state => state.projects.create_project_loading)

  const body = [
    {
      id: 'title',
      type: 'text',
      label: 'Project Title',
      placeholder: 'ex. New Era Residence'
    },
    {
      id: 'location',
      type: 'text',
      label: 'Project Location (Address)',
      placeholder: 'ex. Tomis 25, Constanta'
    },
    {
      id: 'project_amount',
      type: 'number',
      label: 'Project Total Value',
      placeholder: 'ex. 234,356,234'
    },
    {
      id: 'financial_institution_name',
      type: 'text',
      label: 'Financing Bank',
      placeholder: 'ex. Bank of America'
    },
    {
      id: 'currency',
      type: 'select',
      label: 'Currency',
    },
    {
      id: 'beneficiary_name',
      type: 'text',
      label: 'Beneficiary',
      placeholder: 'ex. Company name'
    },
    {
      id: 'credit_amount',
      type: 'number',
      label: 'Total loan value',
      placeholder: 'ex. $2,300.400'
    },
  ]

  return (
    <Formik
      initialValues={{
        title: "",
        location: "",
        project_amount: "",
        financial_institution_name: "",
        currency: currencies[0].value,
        beneficiary_name: "",
        credit_amount: ""
      }}
      validationSchema={Yup.object({
        title: Yup.string()
          .max(50, "Must be 50 characters or less")
          .required(t('validations.required')),
        location: Yup.string()
          .max(60, "Must be 60 characters or less")
          .required(t('validations.required')),
        project_amount: Yup.string()
          .required(t('validations.required')),
        financial_institution_name: Yup.string()
          .max(60, "Must be 60 characters or less")
          .required(t('validations.required')),
        beneficiary_name: Yup.string()
          .max(60, "Must be 60 characters or less")
          .required(t('validations.required')),
        credit_amount: Yup.string()
          .required(t('validations.required')),
        currency: Yup.string()
          .required(t('validations.required')),
      })}
      onSubmit={async (values) => {
        dispatch(createProject(values))
      }}
    >
      <Form>
        <DashBoardModalBody>
          {body.map(item => {
            if (item.type === 'select') {
              return (
                <FormikSelect
                  key={item.id}
                  styles={customCurrencySelect}
                  defaultValue={currencies[0]}
                  label={item.label}
                  name={item.id}
                  options={currencies}
                  iid={item.id}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => <ArrowUp />
                  }}
                />
              )
            }
            return (
              <FormikInput
                key={item.id}
                label={item.label}
                name={item.id}
                type={item.type}
                placeholder={item.placeholder}
                showIcon
              />
            )
          })}
        </DashBoardModalBody>
        <SubmitWrapper>
          <CustomLinkButton
            textColor={mainColors.white}
            innerColor={mainColors.neonBlue}
            borderColor={mainColors.neonBlue}
            text={saving ? 'saving...' : t('projects.modal.button')}
            type='submit'
            button
          />
        </SubmitWrapper>
      </Form>
    </Formik>
  )
}

const ProjectDraw = ({ project, url }) => {
  const { t } = useTranslation()

  return (
    <div className="project-draw">
      {project.project_details?.current_draw === 0 ? (
        <div className="project-draw-start">
          <div>{t('projects.draw.draw_unavailable')}</div>
          <div>
            <NavLink to={{
              pathname: url,
              state: { id: project.id }
            }}>
              {t('projects.draw.start_uploading')}
            </NavLink>
            <span>{t('projects.draw.project_documents')}</span>
          </div>
        </div>
      ) : (
        <>
          <div className="draw-value">
            <div>Draw {project.project_details?.current_draw}</div>
          </div>
          <ProgressBar now={25} />
        </>
      )}
      {false && (
        <span>
          <SmallPlus />
          <a href="/">{t('projects.draw.new_draw')}</a>
        </span>
      )}
    </div>
  )
}

const Projects = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const projects = useSelector(state => state.projects.allProjects)
  console.log('projects: ', projects);
  const loading = useSelector(state => state.projects.loading)

  const ModalContainer = ({
    title: t('projects.modal.title'),
    body: <ModalBody />,
    showFooter: false,
    showClose: true
  })

  const HeaderInfo = (
    <Header>
      <h2>{t('dashboardMenu.projects')}</h2>
      <AddProject onClick={() => dispatch(openModal(ModalContainer))}>
        <Plus />
      </AddProject>
    </Header>
  )

  let projectsContent
  if (projects?.object_list?.length === 0 || projects?.length === 0 || typeof projects === 'undefined') {
    projectsContent = (
      <div>
        {t('projects.no_projects')}
      </div>
    )
  } else {
    projectsContent = projects?.object_list?.map(project => {
      return (
        <ProjectWrapper key={project.id}>
          <div className="project-details">
            <NavLink
              className="header-nav-title"
              to={{
                pathname: linkPath(project),
                state: { id: project.id }
              }}
            >
              <h4>{project.title}</h4>
            </NavLink>
            <div className="project-address">
              <MapPin />
              {project.address}
            </div>
            <div className="project-bank">
              <div className="project-bank-currency">
                {project.currency}&nbsp;
                  {convertToInternationalCurrencySystem(project.project_details?.project_amount)}
              </div>
              <div className="project-bank-name">
                <div className="icon"><Bank /></div>
                <div className="name" title={project.project_details?.financial_institution_name}>{project.project_details?.financial_institution_name}</div>
              </div>
            </div>
            <ProjectDraw
              project={project}
              url={linkPath(project)}
            />
          </div>
          <div className="project-image">
            <NavLink to={{
              pathname: linkPath(project),
              state: { id: project.id }
            }}
            >
              <img src={ProjectImage} alt="Project" />
              <StyledLtcBadge percent={40} />
            </NavLink>
          </div>
        </ProjectWrapper>
      )
    })
  }

  useEffect(() => {
    dispatch(getProjects())
  }, [dispatch])

  return (
    <DashLayout Component={HeaderInfo}>
      <PageMetaTitle title={t('dashboardMenu.projects')} />
      {loading ? (
        <LoaderWrapperFull>
          <Loading />
        </LoaderWrapperFull>
      ) : (
        <Grid>
          {projectsContent}
        </Grid>
      )}
    </DashLayout>
  )
}

export default Projects

