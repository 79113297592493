import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { formatNumber, localToken, userId } from '../../components/helpers'
import { api, headers, tableKeys, TOKEN_TYPE } from '../../constants/constants'
import { fetchTableData } from '../actions/table'

const url = (key, projectId) => {
  switch(key) {
    case tableKeys.budget_documents_table:
      return api.budget_base + api.budget_documents + api.budget + '?projectId=' + projectId
    case tableKeys.budget_lines_table:
      return api.projects_base + api.budget_categories + '?projectId=' + projectId + '&elem=100&page=0'
    case tableKeys.budget_contracts_table:
      return api.budget_base + api.budget_documents + api.contracts + '?projectId=' + projectId + '&elem=100&page=0'
    case tableKeys.budget_contracts_lines_table:
      return api.budget_base + api.budget_documents + api.budget + '?projectId=' + projectId
    case tableKeys.budget_invoices_table:
      return api.budget_base + api.budget_documents + api.budget + '?projectId=' + projectId
    case tableKeys.users_monitor_table:
      return api.base + api.users
    default:
      return 'foo'
  }
}

const create_url = (key) => {
  switch(key) {
    case tableKeys.budget_lines_table:
      return api.projects_base + api.budget_categories
    default:
      return 'foo'
  }
}

const update_url = (key) => {
  switch(key) {
    case tableKeys.budget_lines_table:
      return api.projects_base + api.budget_categories + api.budget_category_update
    default:
      return 'foo'
  }
}

const delete_url = (key, rowId) => {
  switch(key) {
    case tableKeys.budget_lines_table:
      return api.projects_base + api.budget_categories + api.delete_budget_category + '/' + rowId
    default:
      return 'foo'
  }
}

function getTableDataApi(key, projectId) {
  return fetch(url(key, projectId), {
    method: 'GET',
    headers: {
      ...headers,
      'Authorization': TOKEN_TYPE + ' ' + localToken()
    },
  }).then(response => response.json())
    .catch((error) => {throw error})
}

function createTableDataApi(key, values, id) {
  const { data, type } = values
  const body = JSON.stringify({ 
    "amount": formatNumber(data.amount),
    "executed_amount": formatNumber(data.executed_amount),
    "initial_contribution": 0,
    "label": data.label,
    "project_id": id,
    "type": type
  })

  return fetch(create_url(key), {
    method: 'POST',
    headers: {
      ...headers,
      'Authorization': TOKEN_TYPE + ' ' + localToken()
    },
    body: body,
  }).then(response => response.json())
    .catch((error) => {throw error})
}

function updateTableDataApi(key, values) {
  const { data, type } = values
  const body = JSON.stringify({
    "amount": formatNumber(data.amount),
    "budget_category_id": data.id,
    "initial_contribution": 0,
    "label": data.label,
    "type": type
  })

  return fetch(update_url(key), {
    method: 'POST',
    headers: {
      ...headers,
      'Authorization': TOKEN_TYPE + ' ' + localToken()
    },
    body: body,
  }).then(response => response.json())
    .catch((error) => {throw error})
}

function deleteTableRowApi(table_key, rowId) {
  return fetch(delete_url(table_key, rowId), {
    method: 'GET',
    headers: {
      ...headers,
      'Authorization': TOKEN_TYPE + ' ' + localToken()
    },
  }).then(response => response.json())
    .catch((error) => {throw error})
}


function* getTableData({ key, projectId }) {
  try {
    const data = yield call(() => getTableDataApi(key, projectId))
    
    yield put({ type: 'GET_TABLE_DATA_SUCCESS', data: data.object_list, key: key })

  } catch(error) {
    yield put({ type: 'GET_TABLE_DATA_FAILED', message: error.message })
  }
}

function* updateTableData({ key, values, id }) {
  try {
    if(typeof values.data.newRow === 'undefined') {
      console.log('update')
      yield call(() => updateTableDataApi(key, values))
    } else {
      yield call(() => createTableDataApi(key, values, id))
    }

    yield put({ type: 'UPDATE_TABLE_DATA_SUCCESS' })
    yield put(fetchTableData(key, id))

  } catch(e) {
    yield put({ type: 'UPDATE_TABLE_DATA_FAILED', message: e.message })
  }
}

function* deleteTableRow({ rowId, table_key, project_id }) {
  try {
    yield call(() => deleteTableRowApi(table_key, rowId))

    yield put({ type: 'DELETE_TABLE_DATA_ROW_SUCCESS' })
    yield put(fetchTableData(table_key, project_id))

  } catch(e) {
    yield put({ type: 'DELETE_TABLE_DATA_ROW_FAILED', message: e.message })
  }
}

function* watchFetchTableData() {
  yield takeEvery('GET_TABLE_DATA_REQUESTED', getTableData)
}

function* watchUpdateTableData() {
  yield takeLatest('UPDATE_TABLE_DATA_REQUESTED', updateTableData)
}

function* watchDeleteRow() {
  yield takeLatest('DELETE_TABLE_DATA_ROW_REQUESTED', deleteTableRow)
}

export default function* tablesSaga() {
  yield all([
    watchFetchTableData(),
    watchUpdateTableData(),
    watchDeleteRow()
  ])
}
