import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { actionIcons, mainColors, tableKeys } from '../../constants/constants'
import ProjectLayout from './ProjectLayout'
import CustomLinkButton from '../../components/CustomLinkButton'
import CustomTable, { defaultColumns } from '../../components/CustomTable'
import { CustomCellWrapper, HeaderWrapper, Loading, StyledSearchInput } from '../../components/sharedStyles'
import { fetchTableData, toggleActiveRow } from '../../redux/actions/table'
import CustomTableFilters from '../../components/CustomTableFilters'
import DropZone from '../../components/DropZone'

const UploadWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(32, 129, 250, 0.9);
  z-index: 2;

  .fine-uploader-dropzone-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
  }
`
const CustomCell = styled.span`
  padding: 1px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 10px;

  ${({ $color }) => $color === 'Ongoing' && css`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2081FA;
    border: 1px solid #2081FA;
    color: #2081FA;
  `}

  ${({ $color }) => $color === 'Ended' && css`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #002375;
    border: 1px solid #002375;
    color: #002375;
  `}
`

const Invoices = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()

  const toggleTableHeaders = useSelector(state => state.tables.budget_invoices_table)
  const activeRowId = useSelector(state => state.tables.budget_invoices_table.active_row)

  const loadingInvoices = useSelector(state => state.tables.budget_invoices_table.loading)
  const invoicesTableData = useSelector(state => state.tables.budget_invoices_table.data)

  const projectId = location.state?.id

  const [showUpload, setShowUpload] = useState(false)

  const COLUMNS_PENDING = [
    {
      Header: 'Document',
      Footer: 'Document',
      accessor: 'document_name',
      disableFilters: true,
      doNotHide: true,

      Cell: (tableProps) => <CustomCellWrapper>{tableProps.value}</CustomCellWrapper>
    },
    {
      Header: 'Upload Date',
      Footer: 'Upload Date',
      accessor: 'uploaded_at',

      Cell: (tableProps) => {
        const event = new Date(tableProps.value)
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        const date = event.toLocaleDateString('en-GB', options)
        return (
          <CustomCellWrapper>
          <CustomCell>
            {date}
          </CustomCell>
        </CustomCellWrapper>
        )
      }
    },
  ]

  const [outsideFilter, setOutsideFilter] = useState('')

  useEffect(() => {
    dispatch(fetchTableData(tableKeys.budget_invoices_table, projectId))
  }, [dispatch, projectId])

  return (
    <ProjectLayout
      header={
        <HeaderWrapper>
          <div>
            <h2>{t('project.routes.invoices')}</h2>
            <StyledSearchInput
              type="text"
              id="title"
              required
              placeholder='Search'
              value={outsideFilter || ''}
              onChange={e => {
                setOutsideFilter(e.target.value);
              }}
            />
          </div>
          <CustomTableFilters
            columns={COLUMNS_PENDING}
            headers={toggleTableHeaders.headers}
            table_key={tableKeys.budget_invoices_table}
          />
        </HeaderWrapper>
      }
      footer={
        <CustomLinkButton
          type="button"
          onClick={() => setShowUpload(true)}
          textColor={mainColors.white}
          innerColor={mainColors.neonBlue}
          borderColor={mainColors.neonBlue}
          text='Upload invoice'
          button
        />
      }
      footerAlignment="flex-end"
    >
      {showUpload && (
        <UploadWrapper onClick={() => setShowUpload(false)}>
          <DropZone />
        </UploadWrapper>
      )}

      <div>
        {loadingInvoices ? (
          <Loading />
        ) : (
          <CustomTable
            table_key={tableKeys.budget_invoices_table}
            table_data={invoicesTableData}
            cols={COLUMNS_PENDING}
            showStandardColumns
            show_action_items={[actionIcons.delete]}
            showPagination={false}
            size='2fr 2.5fr 1fr'
            outsideFilter={outsideFilter}
            hideHeaders={toggleTableHeaders.hide_headers}
            activeRowId={activeRowId}
            toggleActiveRow={toggleActiveRow}
          />
        )}
      </div>
    </ProjectLayout>
  )
}

export default Invoices
