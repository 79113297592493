import React, { useMemo, useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Formik, Form, useField, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'react-bootstrap'
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table'
import { CustomCellWrapper, customUsersSelect, StyledInputDashboard } from './sharedStyles'
import { closeModal, openModal } from '../redux/actions/modal-custom'
import NumberFormat from 'react-number-format'
import { isObject, isEqual } from 'lodash'
import Select from 'react-select'
import { deleteTableRow, updateTableData } from '../redux/actions/table'
import { usePrevious } from './hooks/hooks'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { ReactComponent as Sort } from '../assets/icons/sort.svg'
import { ReactComponent as SortUp } from '../assets/icons/sort_up.svg'
import { ReactComponent as SortDown } from '../assets/icons/sort_down.svg'
import { ReactComponent as Check } from '../assets/icons/check-slim.svg'
import { ReactComponent as X } from '../assets/icons/x-slim.svg'
import { ReactComponent as PencilLine } from '../assets/icons/pencil_line.svg'
import { ReactComponent as Trash } from '../assets/icons/trash.svg'
import { ReactComponent as Reject } from '../assets/icons/x.svg'
import { ReactComponent as Approve } from '../assets/icons/check-slim.svg'
import { ReactComponent as Download } from '../assets/icons/download_simple.svg'
import { ReactComponent as Eye } from '../assets/icons/eye.svg'
import { actionIcons } from '../constants/constants'
import { checkItem } from './helpers'

const TableWrapper = styled.div`
  margin-bottom: 40px;
  height: 100%;

  ol.collection {
    margin: 0px;
    padding: 0px;
  }

  li {
    list-style: none;
    font-size: 12px;
    position: relative;
  }

  * {
      box-sizing: border-box;
  }

  .attribute {
    line-height: 2;
  }

  /* 2 Column Card Layout */
  @media screen and (max-width: 736px) {

    /* Don't display the first item, since it is used to display the header for tabular layouts*/
    .collection-container>li:first-child {
      display: none;
    }

    /* Attribute name for first column, and attribute value for second column. */
    .attribute {
      display: grid;
      grid-template-columns: minmax(9em, 30%) 1fr;
    }
  }

  /* 1 Column Card Layout */
  @media screen and (max-width:580px) {
    .collection-container {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  /* Tabular Layout */
  @media screen and (min-width: 737px) {
    /* The maximum column width, that can wrap */
    .item-container, form {
      display: grid;
      grid-template-columns: repeat( auto-fit, minmax(50px, 1fr) );
    }

    .collection {
      border-top: 1px solid #F0F2F3;
      border-left: 1px solid #F0F2F3;
    }

    /* In order to maximize row lines, only display one line for a cell */
    .attribute {
        border-right: 1px solid #F0F2F3;
        border-bottom: 1px solid #F0F2F3;
    }

    /* Center header labels */
    .collection-container>.item-container:first-child .attribute {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-overflow: initial;
        overflow: auto;
        white-space: normal;
    }
}

  form {
    width: 100%;
  }

  input {
    border: none;
    background: transparent;
    width: 100%;
    height: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  
    &:disabled {
      color: #212529;
    }

    &:focus {
      border: none;
    }
  
    &:focus-visible {
      outline: none;
    }
  
    &::placeholder {
      color: #2081FA;
    }
  }

  span {
    cursor: pointer;
  }

  .active-row {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: 2px solid #2081FA;
  }

  tr:hover {
    background: transparent;
    --bs-table-accent-bg: transparent !important;
  }

  .active-cell {
    background: rgba(196, 196, 196, 0.1);
    height: 100%;
  }

  .active-edit {
    display: none;
  }

  th,
  td {
    font-size: 12px;
    line-height: 2;
    padding: 0;
  }

  .header-format {
    position: relative;
    padding: 16px;
    font-weight: bold;

    span {
      position: absolute;
      right: 12px;
    }
  }

  th {
    padding: 16px;
  }

  a,
  span {
    border: none;
  }

  .sr-only {
    display: none;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .pagination-rows-per-page {
      margin-right: 150px;
      font-size: 12px;
      opacity: .5;

      select {
        border: none;
      }
    }

    .pagination-count-items {
      font-size: 12px;
      opacity: .5;
    }
  }
`

const SubmitButton = styled.button`
  background: none;
  border: none;
`

const InputButtons = styled.span`
  position: absolute;
  left: 10px;
  z-index: 2;
`

const pointer = css `
  cursor: pointer;
  margin-right: 10px;
`

const StyledCheck = styled(Check)`
  ${pointer}
`

const StyledX = styled(X)`
  ${pointer}
`

const StyledTrash = styled(Trash)`
  ${pointer}
`

const StyledPencilLine = styled(PencilLine)`
  ${pointer}
`

const StyledReject = styled(Reject)`
  ${pointer}
`

const StyledApprove = styled(Approve)`
  ${pointer}
`

const StyledDownload = styled(Download)`
  ${pointer}
`

const StyledEye = styled(Eye)`
  ${pointer}
`

export const defaultColumns = (activeRowId, data, dispatch, toggleActiveRow, table_key, show_action_items, approve_action, project_id, revertRowChanges) => ([
  {
    Header: 'Action',
    id: 'delete',
    accessor: 'delete',
    disableSortBy: true,
    disableFilters: true,
    doNotHide: true,

    Cell: (tableProps) => {
      //const originalId = tableProps.cell.row.original.id
      const originalId = tableProps.row.index
      const activeEdit = originalId === activeRowId ? 'active-edit' : ''

      return (
        <CustomCellWrapper>
          {show_action_items.length > 0 && (
            <>
              {checkItem(show_action_items, actionIcons.delete) && (
                <StyledTrash
                  className={activeEdit}
                  style={{ opacity: activeRowId !== null && originalId !== activeRowId ? '.5' : '1' }}
                  onClick={(e) => {
                    if (activeRowId !== null && originalId !== activeRowId) {
                      //disable edit until an action is performed for any other rows
                      e.preventDefault()
                      return false
                    }
                    const ModalContainer = ({
                      title: (<p>Are you sure you want to delete "{tableProps.columns[0].Header} {originalId+1}" ?</p>),
                      button: 'Delete',
                      action: () => {
                        dispatch(deleteTableRow(tableProps.cell.row.original.id, table_key, project_id))
                        dispatch(closeModal())
                      },
                      showFooter: true,
                    })
                    dispatch(openModal(ModalContainer))
                  }}
                />
              )}
              &nbsp;&nbsp;
              {checkItem(show_action_items, actionIcons.edit) && (
                <StyledPencilLine
                  className={activeEdit}
                  style={{ opacity: activeRowId !== null && originalId !== activeRowId ? '.5' : '1' }}
                  onClick={(e) => {
                    if (activeRowId !== null && originalId !== activeRowId) {
                      //disable edit until an action is performed for any other rows
                      e.preventDefault()
                      return false
                    }
                    dispatch(toggleActiveRow(originalId, table_key))
                  }}
                />
              )}

              {checkItem(show_action_items, actionIcons.ocr) && (
                <StyledEye />
              )}

              {checkItem(show_action_items, actionIcons.download) && (
                <StyledDownload onClick={() => console.log('download', tableProps.row.values.document_name)} />
              )}

              {checkItem(show_action_items, actionIcons.reject) && (
                <StyledReject onClick={() => console.log('reject', tableProps.row.values.document_name)} />
              )}

              {checkItem(show_action_items, actionIcons.approve) && (
                <StyledApprove onClick={() => approve_action(originalId)} />
              )}
            </>
        )}

        {originalId === activeRowId && (
          <InputButtons>
            <SubmitButton type="submit"><StyledCheck type="submit" /></SubmitButton>
            <StyledX onMouseDown={() => {
              revertRowChanges()
              dispatch(toggleActiveRow(null, table_key))
            }} />
          </InputButtons>
        )}

        </CustomCellWrapper>
      )
    },
  },
])

defaultColumns.propTypes = {
  show_action_items: PropTypes.array,
}

defaultColumns.defaultProps = {
  show_action_items: [],
}

const CustomInput = ({ label, ...props }) => {
  const { setFieldValue } = useFormikContext()
  const inputRef = useRef()
  const [field, meta] = useField(props)
  const { value, name } = field

  const defaultValues = {
    number: ""
  }

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      props.setIsEditing(false)
    } else if (e.key === 'Escape') {
      console.log('should cancel the change by key')
      inputRef.current.blur()
      props.setIsEditing(false)
      setFieldValue(props.name, props.initialValue)
    }
  }

  const onCancel = () => {
    console.log('should cancel the change by X')
    props.setIsEditing(false)
    inputRef.current.blur()
    setFieldValue(props.name, props.initialValue)
  }

  return (
    <>
      {props.type === 'number' ? (
        <NumberFormat
          getInputRef={inputRef}
          className="modal-number-input"
          prefix={props.currency}
          name={props.name}
          placeholder={props.placeholder}
          thousandSeparator
          value={isObject(value) ? value.formattedValue : value}
          onValueChange={val =>
            setFieldValue(name, val.floatValue || defaultValues[name])
          }
          onFocus={props.onFocus}
          onCancel={onCancel}
          onKeyDown={(e) => handleOnKeyDown(e)}
          disabled={props.disabled}
          {...field}
          onBlur={props.onBlur}
        />
      ) : (
        <StyledInputDashboard
          style={{ paddingLeft: '0px' }}
          ref={inputRef}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onCancel={onCancel}
          onKeyDown={(e) => handleOnKeyDown(e)}
          disabled={props.disabled}
          className="text-input"
          {...field}
          {...props}
        />
      )}

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  )
}

const CustomAsyncSelect = ({ isDisabled, ...props }) => {
  const { t } = useTranslation()
  const selectRef = useRef()
  const { setFieldValue } = useFormikContext()

  const options = props.customOptions


  const onChange = option => {
    setFieldValue("type", option?.id)
    setSelectedOption([option])
  }

  const [selectedOption, setSelectedOption] = useState()

  useEffect(() => {
    const defaultOption = options?.filter(option => option.id === props.initialValue)
    setSelectedOption(defaultOption);
  }, [options, props.initialValue]);

  return (
    <Select
      ref={selectRef}
      onChange={option => onChange(option)}
      options={options}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      getOptionLabel={e => e.title}
      getOptionValue={e => e.id}
      isMulti={false}
      styles={customUsersSelect}
      isDisabled={isDisabled}
      value={selectedOption}

      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null
      }}
      placeholder={t('not_assigned')}
    />
  )
}

CustomAsyncSelect.propTypes = {
  isDisabled: PropTypes.bool
}

CustomAsyncSelect.defaultProps = {
  isDisabled: PropTypes.false
}

const CustomDate = ({ active, initialValue }) => {
  const { setFieldValue } = useFormikContext()

  let date = null
  if(initialValue) {
    date = new Date(initialValue)
  }

  const [selectedDate, setSelectedDate] = useState(date)

  const onChange = date => {
    setFieldValue("date", date)
    setSelectedDate(date)
  }

  return (
    <DatePicker 
      selected={selectedDate}
      dateFormat="dd MMMM, yyyy"
      className="date-picker"
      name="startDate"
      onChange={date => onChange(date)}
      disabled={active}
      placeholderText="No date"
    />
  )
}

// Editable Cell applied on all non-custom table cells and enabled on active
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
  ...props
}) => {
  const { active } = props
  const [isEditing, setIsEditing] = React.useState(false)
  const isActiveEdit = active && isEditing

  const onBlur = () => {
    setIsEditing(false)
  }

  const onFocus = () => {
    setIsEditing(true)
  }

  let customFormikData = {
    type: props.cell.column.componentType === 'email' ? 'email' : 'text'
  }

  if (props.cell.column.componentType === 'inputNumber') {
    customFormikData = {
      type: 'number'
    }
  }

  let component
  switch (props.cell.column.componentType) {
    case 'isSelect':
      component = (
        <CustomAsyncSelect
          name="select"
          isDisabled={!active}
          isActiveEdit={isActiveEdit}
          onFocus={onFocus}
          setIsEditing={setIsEditing}
          onBlur={onBlur}
          customOptions={props.cell.column.selectOptions}
          initialValue={initialValue}
        />
      )
      break
    case 'date':
      component = (
        <CustomDate
          active={!active}
          initialValue={initialValue}
        />
      )
      break
    default:
      component = (
        <CustomInput
          //name={`data.${index}.${[id]}`}
          name={`data.${[id]}`}
          type={customFormikData.type}
          placeholder={active ? "Click to edit this line" : ''}
          disabled={!active}
          isActiveEdit={isActiveEdit}
          setIsEditing={setIsEditing}
          onFocus={onFocus}
          onBlur={onBlur}
          initialValue={initialValue}
          currency={props.currency}
        />
      )
    }

  return (
    <CustomCellWrapper className={isActiveEdit ? 'active-cell' : ''}>
      {component}
    </CustomCellWrapper>
  )
}

const defaultColumn = {
  Cell: EditableCell,
}

const CustomTable = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const formikRef = useRef()

  const {
    cols,
    currency,
    table_data,
    outsideFilter,
    hideHeaders,
    showPagination,
    activeRowId,
    updateMyData,
    skipPageReset,
    toggleActiveRow,
    table_key,
    project_id,
    showStandardColumns,
    show_action_items,
    approve_action,
    size,
  } = props

  const data = useMemo(() => table_data, [table_data])
  const [tableRows, setTableRows] = useState(table_data)

  const revertRowChanges = useCallback(() => {
    formikRef.current?.resetForm()
  }, [])

  const standardColumns = useMemo(
    () => defaultColumns(
      activeRowId,
      data,
      dispatch,
      toggleActiveRow,
      table_key,
      show_action_items,
      approve_action,
      project_id,
      revertRowChanges,
    ),
    [activeRowId, data, dispatch, toggleActiveRow, table_key, show_action_items, approve_action, revertRowChanges, project_id]
  )
  const columns = useMemo(() => cols.concat(showStandardColumns ? standardColumns : []), [cols, standardColumns, showStandardColumns])

  const startCounter = Object.keys(table_data).length
  const [count, setCount] = useState(startCounter + 1)

  useImperativeHandle(ref, () => ({
    onAddLine() {
      let newState = [...tableRows]
      setCount(count + 1)

      let firstObj = Object.assign({}, newState[0])

      if (Object.keys(firstObj).length < 1) {
        const arr = cols.map(el => el.accessor)
        const emptyArr = Array(cols.length).fill("")
        firstObj = Object.assign(...arr.map((v, i) => ({ [v]: emptyArr[i] })))
      }

      Object.keys(firstObj).forEach((i) => i === 'id' ? firstObj[i] = count : firstObj[i] = '')
      const item = { "newRow": "", ...firstObj }
      newState.push(item)

      setTableRows(newState)
      dispatch(toggleActiveRow(firstObj.id - 1, table_key))
    }
  }))

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: tableRows,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        hiddenColumns: hideHeaders,
      },
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const { pageIndex, pageSize } = state

  useEffect(() => {
    setGlobalFilter(outsideFilter)
  }, [setGlobalFilter, outsideFilter])

  let rowsCount = ((pageIndex + 1) * pageSize) - (pageSize - 1) + " - " + (pageIndex + 1) * pageSize
  if (pageCount - 1 === pageIndex) {
    rowsCount = ((pageIndex + 1) * pageSize) - (pageSize - 1) + " - " + rows.length
  }

  const doNotSubmit = (keyEvent) => {
    //don't submit at enter
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  const prevKey = usePrevious(table_key)
  useEffect(() => {
    if (table_key !== prevKey) {
      dispatch(toggleActiveRow(null, table_key))
    }
  }, [dispatch, table_key, toggleActiveRow, prevKey])

  return (
    <TableWrapper>
      <ol className="collection collection-container" {...getTableProps()}>
        {headerGroups.map(headerGroup => (
          <li className="item item-container" {...headerGroup.getHeaderGroupProps()} style={{ gridTemplateColumns: size }}>
            {headerGroup.headers.map(column => (
              <div className="attribute header-format" {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {!column.disableSortBy && (
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <SortDown />
                        : <SortUp />
                      : <Sort />}
                  </span>
                )}
              </div>
            ))}
          </li>
        ))}

        {tableRows.length < 1 ? (
          <div>{t('table.no_data')}</div>
        ) : (
          page.map(row => {
            prepareRow(row)
            const active = parseInt(row.index) === parseInt(activeRowId)

            return (
              <li {...row.getRowProps()}>
                {active && (
                  <div className="active-row"></div>
                )}
                <Formik
                  id="form-table"
                  innerRef={formikRef}
                  initialValues={{
                    data: row.original
                  }}
                  onSubmit={async (values) => {
                    const noChange = isEqual(row.original, values.data)
                    /*                     if(noChange) {
                                          dispatch(toggleActiveRow(null, table_key))
                                          return false
                                        } */

                    dispatch(toggleActiveRow(null, table_key))
                    dispatch(updateTableData(table_key, values, project_id))
                  }}
                >
                  <Form
                    onKeyDown={doNotSubmit}
                    style={{ gridTemplateColumns: size }}
                  >
                    {active ? (
                      row.cells.map(cell => {
                        return <div className="attribute" {...cell.getCellProps()}>{cell.render('Cell', { active: active, currency: currency })}</div>
                      })
                    ) : (
                      row.cells.map(cell => {
                        return <div className="attribute" {...cell.getCellProps()}>{cell.render('Cell', { currency: currency })}</div>
                      })
                    )}
                  </Form>
                </Formik>
              </li>
            )
          }))}
      </ol>

      {showPagination && (
        <Pagination>
          <li className="pagination-rows-per-page">
            {t("table.rows_per_page")}:
            <select
              value={pageSize}
              onChange={e => setPageSize(Number(e.target.value))}>
              {[10, 25, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </li>
          <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
          <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
          <li className="pagination-count-items">
            {rowsCount} of {rows.length} items
          </li>
          <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
          <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
        </Pagination>
      )}

    </TableWrapper>
  )
})

export default CustomTable

CustomTable.propTypes = {
  cols: PropTypes.array.isRequired,
  currency: PropTypes.string,
  table_data: PropTypes.array,
  table_key: PropTypes.string.isRequired,
  showPagination: PropTypes.bool,
  outsideFilter: PropTypes.string,
  hideHeaders: PropTypes.array,
  showStandardColumns: PropTypes.bool,
  show_action_items: PropTypes.array,
  approve_action: PropTypes.func,
}

CustomTable.defaultProps = {
  table_data: [],
  currency: "",
  showPagination: true,
  outsideFilter: '',
  hideHeaders: [],
  showStandardColumns: true,
  show_action_items: [],
  approve_action: () => console.log('default action'),
}

