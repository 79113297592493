import Immutable from 'seamless-immutable'
import { difference } from 'lodash'
import * as type from '../types'
import { tableKeys } from '../../constants/constants'
import MOCK_DATA_TABLE from '../../components/MOCK_DATA_TABLE.json'
import MOCK_DATA_TABLE_1 from '../../components/MOCK_DATA_TABLE_1.json'
import MOCK_DATA_TABLE_2 from '../../components/MOCK_DATA_TABLE_2.json'
import MOCK_DATA_TABLE_3 from '../../components/MOCK_DATA_TABLE_3.json'

const HEADERS = {
  budget_table: ['id', 'budget_item_description', 'cost_type', 'value_no_vat', 'executed_budget', 'remaining_budget', 'delete'],
  contracts_table: ['id', 'supplier', 'contract_number', 'contract_date', 'contract_object', 'status', 'delete'],
  budget_invoices_table: ['uploaded_at'],
  invoices_table: ['id', 'supplier', 'contract_number', 'contract_date', 'contract_object', 'status', 'delete'],
  users_monitor_table: ['email', 'project']
}

const initialState = Immutable({
  budget_documents_table: {
    data: [],
    hide_headers: [],
    headers: HEADERS.budget_table,
    active_row: null,
    loading: false,
  },
  budget_lines_table: {
    data: [],
    hide_headers: [],
    headers: HEADERS.budget_table,
    active_row: null,
    loading: false,
  },
  budget_table: {
    data: MOCK_DATA_TABLE,
    hide_headers: [],
    headers: HEADERS.budget_table,
    active_row: null
  },
  budget_contracts_table: {
    data:[],
    hide_headers: [],
    headers: HEADERS.contracts_table,
    active_row: null
  },
  budget_contracts_lines_table: {
    data:[],
    hide_headers: [],
    headers: HEADERS.contracts_table,
    active_row: null
  },
  budget_invoices_table: {
    data:[],
    hide_headers: [],
    headers: HEADERS.budget_invoices_table,
    active_row: null
  },
  invoices_table: {
    data: MOCK_DATA_TABLE_1,
    hide_headers: [],
    headers: HEADERS.contracts_table,
    active_row: null
  },
  sales_table: {
    data: MOCK_DATA_TABLE_3,
    hide_headers: [],
    headers: HEADERS.contracts_table,
    active_row: null
  },
  users_monitor_table: {
    data: [],
    hide_headers: [],
    headers: HEADERS.users_monitor_table,
    active_row: null,
    loading: false
  },
})

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case type.ADD_TABLE_HEADER: {
      const header = action.header
      const key = action.key

      return Immutable(state)
        .updateIn([key, 'headers'], arr => arr.concat([header]))
    }
    case type.REMOVE_TABLE_HEADER: {
      const key = action.key
      const mutableStateHeaders = Immutable.asMutable(state[key].headers, {deep: true})
      const header = action.header
      const index = mutableStateHeaders.indexOf(header)

      if (index !== -1) {
        mutableStateHeaders.splice(index, 1)
      }

      return Immutable(state)
        .setIn([key, 'headers'], mutableStateHeaders)
    }
    case type.TOGGLE_TABLE_COLUMNS: {
      const key = action.key
      const headers = state[key].headers
      const data = difference(HEADERS[key], headers)

      return Immutable(state)
        .setIn([key, 'hide_headers'], data)
    }
    case type.TOGGLE_ACTIVE_ROW: {
      const id = action.id
      const key = action.key

      return Immutable(state)
        .setIn([key, 'active_row'], id)
    }
    case type.GET_TABLE_DATA_REQUESTED: {
      const { key } = action

      return Immutable(state)
        .setIn([key, 'loading'], true)
    }
    case type.GET_TABLE_DATA_SUCCESS: {
      const { data, key } = action

      return Immutable(state)
        .setIn([key, 'data'], data)
        .setIn([key, 'loading'], false)
    }
    case type.GET_TABLE_DATA_FAILED: {
      const { key } = action

      return Immutable(state)
        .setIn([key, 'loading'], false)
    }

    default:
      return state
  }
}
