import React from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { mainColors } from '../../constants/constants'
import ProjectLayout from './ProjectLayout'
import CustomLinkButton from '../../components/CustomLinkButton'
import { CustomCellWrapper, HeaderWrapper } from '../../components/sharedStyles'
import CustomTable from '../../components/CustomTable'
import { toggleActiveRow } from '../../redux/actions/table'

const CustomCell = styled.span`
  padding: 1px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 10px;

  ${({ $color }) => $color === 'Under Review' && css`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2081FA;
    border: 1px solid #2081FA;
    color: #2081FA;
  `}
`

const DrawManager = () => {
  const { t } = useTranslation()
  const data = useSelector(state => state.tables.budget_table.data)

  const COLUMNS_PENDING = [
    {
      Header: 'Document',
      Footer: 'Document',
      accessor: 'id',
      disableFilters: true,
      doNotHide: true,

      Cell: (tableProps) => <CustomCellWrapper>{tableProps.value}</CustomCellWrapper>
    },
    {
      Header: 'Upload Date',
      Footer: 'Upload Date',
      accessor: 'upload_date',
    },
    {
      Header: 'Status',
      Footer: 'Status',
      accessor: 'status',
      doNotHide: true,

      Cell: (tableProps) => (
        <CustomCellWrapper>
          <CustomCell $color={tableProps.value}>
            {tableProps.value}
          </CustomCell>
        </CustomCellWrapper>
      ),
    },
  ]

  return (
    <ProjectLayout
      header={(
        <HeaderWrapper>
          <h2>{t('project.routes.draw_manager')}</h2>
        </HeaderWrapper>
      )}
      footer={
        <>
          <span />
          <span>
            <span>You have one pending report</span>
            <CustomLinkButton
              type="button"
              onClick={() => console.log('Upload images')}
              textColor={mainColors.white}
              innerColor={mainColors.neonBlue}
              borderColor={mainColors.neonBlue}
              text='Generate Report'
              button
            />
          </span>
        </>
      }
      footerAlignment="space-between"
    >
      <CustomTable
        table_key='budget_table'
        table_data={data}
        cols={COLUMNS_PENDING}
        showPagination
        showStandardColumns
        show_action_items={[]}
        toggleActiveRow={toggleActiveRow}
      />
    </ProjectLayout>
  )
}

export default DrawManager
