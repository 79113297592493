import React from 'react'
import { NavLink, useParams, useHistory, useRouteMatch, Switch, Route, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { LinksWrapper, sharedDashboardHeader } from '../components/sharedStyles'
import { mainColors, urls } from '../constants/constants'
import PageMetaTitle from '../components/PageMetaTitle'
import DashLayout from './partials/DashLayout'

import { ReactComponent as BackArrow } from '../assets/icons/arrow_left.svg'
import { ReactComponent as Wallet } from '../assets/icons/wallet.svg'
import { ReactComponent as FileText } from '../assets/icons/file_text.svg'
import { ReactComponent as Cardholder } from '../assets/icons/cardholder.svg'
import { ReactComponent as Image } from '../assets/icons/image_icon.svg'
import { ReactComponent as CreditCard } from '../assets/icons/credit_card.svg'
import { ReactComponent as FileSearch } from '../assets/icons/file_search.svg'

import Budget from './partials/Budget'
import Contracts from './partials/Contracts'
import Invoices from './partials/Invoices'
import SitePictures from './partials/SitePictures'
import DrawManager from './partials/DrawManager'
import MonitoringReports from './partials/MonitoringReports'

const formatTitle = data => data.split('-').map((word) => (word[0].toUpperCase() + word.substring(1))).join(' ')

const Header = styled.div`
  ${sharedDashboardHeader}
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -20px;

  h2 {
    display: block;
  }
`

const StyledButton = styled.button`
  background: top;
  outline: none;
  border: none;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: ${mainColors.transparentGray};

  svg {
    margin-right: 10px;
    font-weight: 500;
    font-size: 34px;
    line-height: 42px;
    color: ${mainColors.dashboardDarkBlue};
  }
`

export const paths = {
  budget: '/budget',
  contract: '/contract',
  invoices: '/invoices',
  sitePictures: '/site-pictures',
  drawManager: '/draw-manager',
  monitoringReports: '/monitoring-reports'
}

const Project = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  let { projectId } = useParams()
  let { url } = useRouteMatch()

  const linksData = [
    {
      name: t('project.routes.budget'),
      to: url + paths.budget,
      icon: <Wallet />
    },
    {
      name: t('project.routes.contracts'),
      to: url + paths.contract,
      icon: <FileText />
    },
    {
      name: t('project.routes.invoices'),
      to: url + paths.invoices,
      icon: <Cardholder />
    },
    {
      name: t('project.routes.site_pictures'),
      to: url + paths.sitePictures,
      icon: <Image />
    },
    {
      name: t('project.routes.draw_manager'),
      to: url + paths.drawManager,
      icon: <CreditCard />
    },
    {
      name: t('project.routes.monitoring_reports'),
      to: url + paths.monitoringReports,
      icon: <FileSearch />
    },
  ]

  const routesData = [
    {
      Path: urls.project + paths.contract,
      Component: <Contracts />
    },
    {
      Path: urls.project + paths.invoices,
      Component: <Invoices />
    },
    {
      Path: urls.project + paths.sitePictures,
      Component: <SitePictures />
    },
    {
      Path: urls.project + paths.drawManager,
      Component: <DrawManager />
    },
    {
      Path: urls.project + paths.monitoringReports,
      Component: <MonitoringReports />
    },
    {
      Path: urls.project + paths.budget,
      Component: <Budget />
    },
  ]

  const HeaderInfo = (
    <Header>
      <StyledButton onClick={() => history.push(urls.projects)}><BackArrow />Back</StyledButton>
      <h2>{formatTitle(projectId)}</h2>
    </Header>
  )

  const links = linksData.map((link, i) => (
    <li key={i}>
      <NavLink to={{
        pathname: link.to,
        state: { id: location.state?.id }
      }}>{link.icon} {link.name}</NavLink>
    </li>
  ))

  const routes = routesData.map((route, i) => (
    <Route key={i} path={route.Path === urls.project + paths.budget ? "" : route.Path} component={() => route.Component} />
  ))

  return (
    <>
      <PageMetaTitle title={formatTitle(projectId)} />
      <DashLayout Component={HeaderInfo}>
        <LinksWrapper>
          <ul>
            {links}
          </ul>
        </LinksWrapper>

        <Switch>
          {routes}
        </Switch>
      </DashLayout>
    </>
  )
}

export default Project

