import React from 'react'
import { useSelector } from 'react-redux'
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { Role, urls } from './constants/constants'

import Home from './pages/Home/Home'
import Benefits from './pages/Benefits'
import SignIn from './pages/SignIn'
import Contact from './pages/Contact'
import AboutUs from './pages/AboutUs/AboutUs'
/*import Terms from './pages/Terms'
import Policies from './pages/Policies'
import Cookies from './pages/Cookies'*/
import Dashboard from './pages/Dashboard'
import Projects from './pages/Projects'
import Project from './pages/Project'
import Sales from './pages/Sales'
import AccountSettings from './pages/AccountSettings'
import SuperUser from './pages/SuperUser'
import NotFound404 from './pages/NotFound404'
import Error503 from './pages/Error503'
import ComingSoon from './pages/ComingSoon'
import Terms from './pages/Terms'
import { checkAccess } from './components/helpers'

const RestrictedRoute = ({
  isAuth,
  hasAccess,
  component: PageComponent,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if(!isAuth) {
        return <Redirect push to={urls.sign_in} />
      }

      if (!checkAccess(hasAccess)) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: urls.dashboard }} />
      }

      return <PageComponent {...props} />
    }}
  />
)

export default function Routes() {
  const isAuth = useSelector(state => state.auth.isAuth)

  return (
    <Switch>
      <Route path={urls.benefits} component={Benefits} />
      <Route path={urls.contact} component={Contact} />
      <Route path={urls.sign_in} component={SignIn} />
      <Route exact path={urls.home} component={Home} />

      <RestrictedRoute
        path={urls.dashboard}
        component={Dashboard}
        isAuth={isAuth}
        hasAccess={[Role.admin, Role.monitor, Role.dev]}
      />

      <RestrictedRoute
        path={urls.projects}
        component={Projects}
        isAuth={isAuth}
        hasAccess={[Role.admin, Role.monitor, Role.dev]}
      />
      <RestrictedRoute
        path={urls.project}
        component={Project}
        isAuth={isAuth}
        hasAccess={[Role.admin, Role.monitor, Role.dev]}
      />

      <RestrictedRoute
        path={urls.sales}
        component={Sales}
        isAuth={isAuth}
        hasAccess={[Role.admin, Role.monitor, Role.dev]}
      />
      <RestrictedRoute
        path={urls.account_settings}
        component={AccountSettings}
        isAuth={isAuth}
        hasAccess={[Role.admin, Role.monitor, Role.dev]}
      />
      <RestrictedRoute
        path={urls.super_user}
        component={SuperUser}
        isAuth={isAuth}
        hasAccess={[Role.admin]}
      />

      <Route path={urls.about_us} component={AboutUs} />
      <Route path={urls.terms} component={Terms} />
      <Route path={urls.privacy} component={ComingSoon} />
      <Route path={urls.cookies} component={ComingSoon} />

      <Route path="*" component={NotFound404} />
    </Switch>
  )
}

