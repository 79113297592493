import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { closeModal } from '../redux/actions/modal-auth'
import { closeModal as closeCustomModal } from '../redux/actions/modal-custom'
import { logout_to } from '../redux/actions/auth'
import { useTranslation } from 'react-i18next'
import { urls } from '../constants/constants'

const AuthModal = () => {
  const dispatch = useDispatch()
  let history = useHistory()

  const show = useSelector(state => state.authModal.show)
  const data = useSelector(state => state.authModal.data)

  const { button_save, button_close, info, isIdle, ref } = data

  const logout = () => { 
    dispatch(logout_to())
    clearTimeout(ref.current)
    dispatch(closeModal())
    dispatch(closeCustomModal())
    localStorage.removeItem('state')
    history.push(urls.sign_in)
  }
  const stayActive = () => { clearTimeout(ref.current); dispatch(closeModal());  }

  const close = () => isIdle ? logout() : dispatch(closeModal())
  const action = () => isIdle ? stayActive() : dispatch(closeModal())

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header>
        <Modal.Title>Warning!</Modal.Title>
      </Modal.Header>
      <Modal.Body> {info || data} </Modal.Body>
      <Modal.Footer>
        {button_close && (
          <Button variant="secondary" onClick={close}>
            {button_close}
          </Button>
          )}
        {button_save && (
          <Button variant="primary" onClick={action}>
            {button_save}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default AuthModal

AuthModal.propTypes = {
  button_save: PropTypes.string,
  button_close: PropTypes.string,
  info: PropTypes.string,
  isIdle: PropTypes.bool,
}

AuthModal.defaultProps = {
  button_save: 'Save',
  button_close: 'Close',
  info: 'No data to display',
  isIdle: false,
}
