import { indexOf } from 'lodash'

export const roleId = () => {
  const {auth: {userData: { role_id }}} = JSON.parse(localStorage.getItem('state'))
  return role_id
}

export const checkAccess = (arr) => {
  return indexOf(arr, roleId()) !== -1
}

export const localToken = () => {
  const {auth: { isAuth }} = JSON.parse(localStorage.getItem('state'))
  return isAuth
}

export const userId = () => {
  const {auth: { userData: { id }}} = JSON.parse(localStorage.getItem('state'))
  return id
}

export const convertToInternationalCurrencySystem = (labelValue) => {

  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

  ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
  // Six Zeroes for Millions 
  : Math.abs(Number(labelValue)) >= 1.0e+6

  ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
  // Three Zeroes for Thousands
  : Math.abs(Number(labelValue)) >= 1.0e+3

  ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"

  : Math.abs(Number(labelValue))

}

export const formatNumber = (number) => {
  const formattedNumber = number.replace(/\D/g,'')
  return formattedNumber
}

export const checkItem = (arr, val) => {
  return indexOf(arr, val) !== -1
}
