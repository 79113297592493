import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakPoints, mainColors } from '../constants/constants'
import { customSelect, Loading, sharedDashboardHeader } from '../components/sharedStyles'
import Select from 'react-select'
import { Bar, Doughnut, Line } from 'react-chartjs-2'
import PageMetaTitle from '../components/PageMetaTitle'
import DashLayout from './partials/DashLayout'
import { htmlLegendPluginDohnut } from '../components/plugins/htmlLegendPluginDohnut'

import { ReactComponent as ArrowUp } from '../assets/icons/arrow_drop_up.svg'
import { ReactComponent as Dollar } from '../assets/icons/currency_dollar.svg'
import LtcBadge from '../components/Ltc-badge'
import { getProjects } from '../redux/actions/projects'

const options_graph = [
  { value: 'property_1', label: 'This Year' },
]

const badgesData = [
  {
    icon: <Dollar />,
    cost: '27,000,000 EUR'
  },
  {
    icon: <Dollar />,
    cost: '18,900,000 EUR'
  },
  {
    icon: <Dollar />,
    cost: '5,600,000 EUR'
  },
  {
    icon: <Dollar />,
    cost: '4,500,000 EUR'
  }
]

const Header = styled.div`
  ${sharedDashboardHeader}
`

const MainSection = styled.div`
  margin-top: 30px;
`

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BadgesWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(291px, 1fr));
  grid-gap: 15px;
  justify-content: flex-start;
`

const Badge = styled.div`
  background: ${mainColors.white};
  border-radius: 20px;
  display: flex;
  align-items: center;

  .badge-{
    &currency{
      background: #F4F7FE;
      margin: 20px 18px;
      border-radius: 50%;
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${mainColors.neonBlue};
      font-size: 23px;
    }

    &content{
      div {
        &:first-child {
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: #A3AED0;
        }

        &:last-child {
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;
          color: ${mainColors.dashboardDarkBlue};
        }
      }
    }
  }
`

const ChartsWrapper = styled.div`
  .charts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: ${mainColors.dashboardDarkBlue};
    text-transform: capitalize;
  }

  .charts-body {
    padding-top: 17px;

    .row-one {
      display: grid;
      grid-template-columns: 2fr 1fr;
      /*grid-template-columns: repeat(auto-fit, minmax(2fr, 1fr));*/
      grid-gap: 30px;
      margin-bottom: 30px;

      @media (max-width: ${breakPoints.xs}) {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
      }
    }

    .row-two {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      margin-bottom: 30px;

      @media (max-width: ${breakPoints.xs}) {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
      }
    }

    .chart-wrapper {
      background: #FFFFFF;
      border-radius: 20px;
      padding: 24px;

      &.dohnut {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .chart-title-wrapper {
          text-align: left;
          width: 100%;
        }

        .chart-canvas {
          @media (max-width: ${breakPoints.md}) {
            max-width: 400px;
            max-height: 400px;
          }
          @media (max-width: ${breakPoints.sm}) {
            max-width: 211px;
            max-height: 211px;
          }
        }

        #legend-container {
          display: flex;
          justify-content: center;
        }

        ul {
          flex-wrap: wrap;
          max-width: 300px;
        }
      }

      &.bar {
        .chart-canvas {

        }
      }

      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: ${mainColors.dashboardDarkBlue};
        text-transform: capitalize;
      }

      h5 {
        font-weight: 500;
        font-size: 14px;
        color: #6A7AAB;
        position: absolute;
      }

      &.bar {
        chart-title-wrapper {
          margin-bottom: 20px;
        }
      }

      &.multi-bar,
      &.line {
        .chart-title-wrapper {
          margin-bottom: -30px;

          h3,
          h5 {
            margin: 8px;
          }
        }
      }
    }
  }
`

const Dashboard = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const toggle = useSelector(state => state.general.toggleSidebar)
  const user = useSelector(state => state.auth.userData)

  const HeaderInfo = (
    <Header>
      <h4>{t('dashboard.hi')}, {user?.user_name}</h4>
      <h2>{t('dashboard.welcome')}</h2>
    </Header>
  )

  const badges = badgesData.map((badge, index) => (
    <Badge key={index}>
      <div className="badge-currency">€</div>
      <div className="badge-content">
        <div>{t('dashboard.badges.badge_' + (index + 1) + '.title')}</div>
        <div>{badge.cost}</div>
      </div>
    </Badge>
  ))

  const [barChartData, setBarChartData] = useState({})

  const barChart = () => {
    setBarChartData({
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          data: [0, 500000, 500000, 600000, 800000, 950000, 1400000, 550000, 300000, 0, 0, 0],
          backgroundColor: [
            '#AEC0D6',
          ],
          borderColor: [
            '#AEC0D6',
          ],
          borderWidth: 1,
          borderRadius: 8,
          hoverBackgroundColor: '#2081FA',
          hoverBorderColor: '#2081FA'
        },
      ],
    })
  }


  const [dohnutChartData, setDohnutChartData] = useState({})

  const dohnutChart = () => {
    setDohnutChartData({
      labels: ['Land Costs', 'Hard Costs', 'Soft Costs'],
      datasets: [
        {
          data: [4000000, 20000000, 3000000],
          backgroundColor: [
            '#F43F5E',
            '#2081FA',
            '#FBBF24',
          ],
        },
      ],
    })
  }

  const [lineChartData, setLineChartData] = useState({})

  function getBackgroundGradient(ctx, chartArea) {
    let width, height, gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === null || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(.2, 'rgba(32, 129, 250, 0)');
      gradient.addColorStop(1, 'rgba(32, 129, 250, 0.3)');
    }

    return gradient;
  }

  function getBorderGradient(ctx, chartArea) {
    let width, height, gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === null || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
      gradient.addColorStop(.1, 'rgba(32, 129, 250, 0.3)');
      gradient.addColorStop(1, 'rgba(32, 129, 250, 0.9)');
    }

    return gradient;
  }

  const lineChart = () => {
    setLineChartData({
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          data: [0, 500000, 500000, 600000, 550000, 1250000, 500000, 500000, 300000, 0, 0, 0],
          borderColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return null;
            }
            return getBorderGradient(ctx, chartArea);
          },
          pointBorderColor: 'transparent',
          pointBackgroundColor: 'darkblue',
          pointHoverBackgroundColor: 'transparent',
          pointHoverBorderColor: 'transparent',
          pointBorderWidth: 10,
          pointHoverRadius: 10,
          pointHoverBorderWidth: 0,
          pointRadius: 5,
          fill: true,
          backgroundColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return null;
            }
            return getBackgroundGradient(ctx, chartArea);
          },
          borderWidth: 4,
        },
      ],
    })
  }

  const [multiBarChartData, setMultiBarChartData] = useState({})

  const multiBarChart = () => {
    setMultiBarChartData({
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Own equity",
          backgroundColor: "rgba(0, 35, 117, 0.6)",
          data: [3, 7, 11, 2, 5, 2, 9, 7, 1, 4, 10, 2],
          borderRadius: 12,
          hoverBackgroundColor: mainColors.dashboardDarkBlue,
          borderSkipped: false
        },
        {
          label: "Loan value",
          backgroundColor: "rgba(0, 73, 255, 0.6)",
          data: [2, 8, 9, 4, 6, 8, 7, 7, 1, 4, 3, 4],
          borderRadius: 12,
          hoverBackgroundColor: "#0049FF",
          borderSkipped: false
        },
      ],
    })
  }

  const ProjectsSelect = () => {
    useEffect(() => {
      dispatch(getProjects())
    }, [])

    const options = useSelector(state => state.projects?.allProjects?.object_list)
  
    if(!options) return <Loading />
  
    const defaultOption = [{ id: options[0]?.id, title: options[0]?.title }]
  
    return (
      <Select
        styles={customSelect}
        maxWidth='252px'
        options={options}
        getOptionLabel={e => e.title}
        getOptionValue={e => e.id}
        defaultValue={defaultOption}
        onChange={option => console.log(option.id)}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => <ArrowUp />
        }}
      />
    )
  }

  useEffect(() => {
    dohnutChart()
    barChart()
    lineChart()
    multiBarChart()
  }, [])

  return (
    <DashLayout Component={HeaderInfo}>
      <PageMetaTitle title={t('dashboardMenu.dashboard')} />
      <MainSection>
        <Top>
          <ProjectsSelect />
          <LtcBadge percent={70} />
        </Top>
        <BadgesWrapper>
          {badges}
        </BadgesWrapper>
        <ChartsWrapper key={toggle}>
          <div className="charts-header">
            <div className="header-title">{t('dashboard.title')}</div>
            <Select
              styles={customSelect}
              maxWidth='154px'
              defaultValue={{ value: 'property_1', label: 'This Year' }}
              options={options_graph}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => <ArrowUp />
              }}
            />
          </div>
          <div className="charts-body">

            <div className="row-one">
              <div className="chart-wrapper bar">
                <div className="chart-title-wrapper">
                  <h3>Debt Tracker</h3>
                  <h5>Loan Value: €25,000,000</h5>
                </div>

                <div className="chart-canvas">
                  <Bar
                    data={barChartData}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          enabled: true,
                          displayColors: false,
                          backgroundColor: mainColors.darkBlue,
                          caretSize: 0,
                          callbacks: {
                            label: function (tooltipItem) {
                              return "€" + tooltipItem.formattedValue
                            },
                            title: function (tooltipItems, data) {
                              return '';
                            },
                          },
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false,
                            drawBorder: false,
                          }
                        },
                        y: {
                          display: false,
                          grid: {
                            drawBorder: false,
                            display: false,
                          }
                        },
                      }
                    }}
                  />
                </div>
              </div>

              <div className="chart-wrapper dohnut">
                <div className="chart-title-wrapper">
                  <h3>Budget Structure</h3>
                </div>
                <div className="chart-canvas">
                  <Doughnut
                    data={dohnutChartData}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                        htmlLegend: {
                          containerID: 'legend-container',
                        },
                      },
                    }
                    }
                    plugins={[htmlLegendPluginDohnut]}
                  />
                </div>
                <div id="legend-container"></div>
              </div>
            </div>

            <div className="row-two">
              <div className="chart-wrapper line">
                <div className="chart-title-wrapper">
                  <h3>Equity Tracker</h3>
                  <h5>Equity value: €6,256,209</h5>
                </div>
                <div className="chart-canvas">
                  <Line
                    data={lineChartData}
                    type='line'
                    options={{
                      elements: {
                        line: {
                          tension: 0.5, // disables bezier curves
                        }
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          enabled: true,
                          displayColors: false,
                          backgroundColor: mainColors.darkBlue,
                          caretSize: 0,
                          callbacks: {
                            label: function (tooltipItem) {
                              return "€" + tooltipItem.formattedValue
                            },
                            title: function (tooltipItems, data) {
                              return '';
                            },
                          },
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false,
                            drawBorder: false,
                          }
                        },
                        y: {
                          display: false,
                          grid: {
                            drawBorder: false,
                            display: false,
                          }
                        },
                      }
                    }}
                  />
                </div>
              </div>

              <div className="chart-wrapper multi-bar">
                <div className="chart-canvas">
                  <div className="chart-title-wrapper">
                    <h3>Project Value Tracker</h3>
                  </div>
                  <Bar
                    data={multiBarChartData}
                    type='line'
                    options={{
                      plugins: {
                        legend: {
                          display: true,
                          position: 'top',
                          align: 'end',
                          labels: {
                            boxWidth: 6,
                            boxHeight: 6,
                            usePointStyle: true,
                            pointStyle: 'circle'
                          }
                        },
                        tooltip: {
                          enabled: true,
                          displayColors: false,
                          backgroundColor: mainColors.darkBlue,
                          caretSize: 0,
                          xAlign: 'center',
                          yAlign: 'bottom',
                          callbacks: {
                            label: function (tooltipItem) {
                              return "€" + Number(tooltipItem.formattedValue)
                            },
                            title: function (tooltipItems, data) {
                              return ''
                            },
                          },
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false,
                            drawBorder: false,
                          }
                        },
                        y: {
                          display: false,
                          grid: {
                            drawBorder: false,
                            display: false,
                          }
                        },
                      }
                    }}
                  />
                </div>
              </div>
            </div>


          </div>
        </ChartsWrapper>
      </MainSection>
    </DashLayout>
  )
}

export default Dashboard

