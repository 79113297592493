import { all, call, put, takeLatest } from 'redux-saga/effects'
import { formatNumber, localToken, userId } from '../../components/helpers'
import { api, headers, TOKEN_TYPE } from '../../constants/constants'
import { closeModal } from '../actions/modal-custom'
import { getProjects } from '../actions/projects'

function getProjectsApi() {
  return fetch(api.projects_base + api.projects + '?filter=USERID&value=' + userId() + '&elem=100&page=0', {
    method: 'GET',
    headers: {
      ...headers,
      'Authorization': TOKEN_TYPE + ' ' + localToken()
    },
  }).then(response => response.json())
    .catch((error) => {throw error})
}

function createProjectApi(values, userId) {
  const { title, location, project_amount, financial_institution_name, currency, beneficiary_name, credit_amount } = values

  return fetch(api.projects_base + api.projects, {
    method: 'POST',
    headers: {
      ...headers,
      'Authorization': TOKEN_TYPE + ' ' + localToken()
    },
    body: JSON.stringify({ 
      address: location,
      beneficiary_name: beneficiary_name,
      currency: currency,
      financial_institution_name: financial_institution_name,
      image_url: "",
      title: title,
      user_id: userId,
      project_amount: formatNumber(project_amount),
      credit_amount: formatNumber(credit_amount)
    })
  }).then(response => response.json())
    .catch((error) => {throw error})
}

function fetchProjectDetailsApi(title) {
  return fetch(api.projects_base + '/projects/' + title, {
    method: 'GET',
    headers: {
      ...headers,
      'Authorization': TOKEN_TYPE + ' ' + localToken()
    },
  }).then(response => response.json())
    .catch((error) => {throw error})
}


function* fetchProjects() {
  try {
    const projects = yield call(getProjectsApi)
    
    yield put({ type: 'GET_PROJECTS_SUCCESS', projects: projects })
  } catch(e) {
    yield put({ type: 'GET_PROJECTS_FAILED', message: e.message })
  }
}

function* createProject({ values }) {
  try {
    yield call(() => createProjectApi(values, userId()))

    yield put({ type: 'CREATE_PROJECT_SUCCESS' })
    yield put(closeModal())
    yield put(getProjects())
  } catch(e) {
    yield put({ type: 'CREATE_PROJECT_FAILED', message: e.message })
  }
}

function* fetchProjectDetails({ title }) {
  try {
    const details = yield call(() => fetchProjectDetailsApi(title))

    yield put({ type: 'GET_PROJECT_DETAILS_SUCCESS', project_details: details })
  } catch(e) {
    yield put({ type: 'GET_PROJECT_DETAILS_FAILED', message: e.message })
  }
}

function* watchFetchProjects() {
  yield takeLatest('GET_PROJECTS_REQUESTED', fetchProjects)
}

function* watchCreateProject() {
  yield takeLatest('CREATE_PROJECT_REQUESTED', createProject)
}

function* watchFetchProjectDetails() {
  yield takeLatest('GET_PROJECT_DETAILS_REQUESTED', fetchProjectDetails)
}

export default function* projectsSaga() {
  yield all([
    watchFetchProjects(),
    watchCreateProject(),
    watchFetchProjectDetails()
  ])
}
